<template>
  <div>
    <div class="invoice-box parent">
      <div class="child1">
        <img class="px-0 img" src="@/assets/original.png" />
      </div>

      <table cellpadding="0" cellspacing="0">
        <tr class="information">
          <td colspan="5">
            <table>
              <tr>
                <td>
                  <img
                    class="px-0"
                    style="width: 40%; max-width: 120px"
                    :src="entete.logo_url"
                  />
                </td>

                <td>
                  <div class="">
                    <h2 class="font-weight-black">{{ entete.name }}</h2>
                    <h5 class="font-weight-black">{{ entete.telephone }}</h5>
                    <h5 class="text-uppercase font-weight-black">
                      {{ entete.address }}
                    </h5>
                    {{ date }}
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="top">
          <td colspan="5">
            <table>
              <tr class="text-center head">
                <td class="text-center">
                  <div class="text-uppercase text-center">
                    <h2 class="">
                      {{
                        toPrint.type == "transfert"
                          ? "FICHE DE TRANSACTION"
                          : "FICHE D'OPERATION"
                      }}
                    </h2>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="heading">
          <td>Opération</td>
          <td>Libellé</td>
          <td v-if="toPrint.type == 'transfert'">Débité</td>
          <td v-if="toPrint.type == 'transfert'">Débiteur</td>
          <td>Montant</td>
        </tr>

        <tr class="details">
          <td>{{ toPrint.operation }}</td>
          <td>{{ toPrint.libelle }}</td>
          <td v-if="toPrint.type == 'transfert'">{{ toPrint.debite ? toPrint.debite : "-----" }}</td>
          <td v-if="toPrint.type == 'transfert'">{{ toPrint.debiteur ? toPrint.debiteur : "-----" }}</td>
          <td>{{ $thousandSeparator(toPrint.montant) }}</td>
        </tr>

        <tr class="total">
          <td :colspan="toPrint.type == 'transfert' ? 4 : 2"></td>
          <td>{{ $thousandSeparator(toPrint.montant) }}</td>
        </tr>
        <td style="bottom: 0; font-size: 10px; opacity: 0.5">
          Edité par FlashCar
        </td>
      </table>
    </div>

    <div class="my-2">
      <hr />
    </div>

    <div class="invoice-box parent">
      <div class="child1">
        <img class="px-0 img" src="@/assets/copie.png" />
      </div>

      <table cellpadding="0" cellspacing="0">
        <tr class="information">
          <td colspan="5">
            <table>
              <tr>
                <td>
                  <img
                    class="px-0"
                    style="width: 40%; max-width: 120px"
                    :src="entete.logo_url"
                  />
                </td>

                <td>
                  <div class="">
                    <h2 class="font-weight-black">{{ entete.name }}</h2>
                    <h5 class="font-weight-black">{{ entete.telephone }}</h5>
                    <h5 class="text-uppercase font-weight-black">
                      {{ entete.address }}
                    </h5>

                    {{ date }}<br />
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="top">
          <td colspan="5">
            <table>
              <tr class="text-center head">
                <td class="text-center">
                  <div class="text-uppercase text-center">
                    <h2 class="">
                      {{
                        toPrint.type == "transfert"
                          ? "FICHE DE TRANSACTION"
                          : "FICHE D'OPERATION"
                      }}
                    </h2>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="heading">
          <td>Opération</td>
          <td>Libellé</td>
          <td v-if="toPrint.type == 'transfert'">Débité</td>
          <td v-if="toPrint.type == 'transfert'">Débiteur</td>
          <td>Montant</td>
        </tr>

        <tr class="details">
          <td>{{ toPrint.operation }}</td>
          <td>{{ toPrint.libelle }}</td>
          <td v-if="toPrint.type == 'transfert'">{{ toPrint.debite ? toPrint.debite : "-----" }}</td>
          <td v-if="toPrint.type == 'transfert'">{{ toPrint.debiteur ? toPrint.debiteur : "-----" }}</td>
          <td>{{ $thousandSeparator(toPrint.montant) }}</td>
        </tr>

        <tr class="total">
          <td :colspan="toPrint.type == 'transfert' ? 4 : 2"></td>
          <td>{{ $thousandSeparator(toPrint.montant) }}</td>
        </tr>
        <td style="bottom: 0; font-size: 10px; opacity: 0.5">
          Edité par FlashCar
        </td>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toPrint: {},
      entete: {},
    };
  },

  mounted() {
    // this.societesData()
    // let data = this.$router.currentRoute.query
    // this.toPrint = data

    let data = this.$router.currentRoute.query;
    this.toPrint = data;
    // console.log('toPrint', this.toPrint)
    this.$nextTick(() => {
      setTimeout(() => {
        this.societesData();
      }, 1000);
    });
  },

  computed: {
    date() {
      var today = new Date();
      let annee = today.getFullYear();
      let mois = ("0" + (today.getMonth() + 1)).slice(-2);
      let jour = ("0" + today.getDate()).slice(-2);
      var date =
        jour +
        "/" +
        mois +
        "/" +
        annee +
        " " +
        today.getHours() +
        ":" +
        today.getMinutes();

      return date;
    },

    image() {
      return "@/assets/original.png";
    },
  },

  methods: {
    telecharger() {
      window.print();
    },

    async societesData() {
      await this.$http.get("/societes").then((response) => {
        this.entete = response.data.data.find(({ id }) => id === 1);
        if (!this.entete) this.entete = {};
        setTimeout(() => {
          window.print();
        }, 2000);
      });
    },
  },
};
</script>

<style lang="scss">
@page {
  size: landscape;
}

.invoice-box {
  max-width: 800px;
  padding: 30px;
  border: 1px solid #eee;
  font-size: 16px;
  line-height: 24px;
  color: #555;
  margin: 10px auto !important;
}

.head {
  text-align: center !important;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
  padding: 0px !important;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .child1 {
    position: absolute;
    .img {
      width: 100%;
      max-width: 100vw;
    }
  }

  .child2 {
    right: 0;
    margin-top: 10px !important;
    padding-right: 20px !important;
    font-size: 1.5em;
    font-weight: bold;
  }

  .child3 {
    top: 100px;
    z-index: 1;
    font-size: 4em !important;
    // margin: auto 50px !important;
    border-radius: 10px;
  }
}
</style>
